import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarrierSales } from '../carrier-sales-v2/list';
import { CreateCarrierBidComponent } from '../carrier-bids/components/create-carrier-bid/create-carrier-bid.component';
import { ThirdPartyPostListComponent } from '../third-party-post/list';
import { ThirdPartyPostComponent } from '../third-party-post/post';
import { CarrierSaleDetailComponent } from '../carrier-sale-detail/detail';
import { BidSessionCreateComponent } from '../bid-sessions/create';
import { BidSessionDetailComponent } from '../bid-sessions/detail';
import { BidSessionAddRouteComponent } from '../bid-sessions/add-routes';


const carrierSalesRoutes: Routes = [
  { path: '', component: CarrierSales, data: { title: 'Carrier Sales - WARP Admin' } },
  { path: 'create/:jobId', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: 'edit/:id', component: CreateCarrierBidComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id/third-party-post', component: ThirdPartyPostListComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id/third-party-post/add', component: ThirdPartyPostComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id/third-party-post/:postId', component: ThirdPartyPostComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: ':id', component: CarrierSaleDetailComponent, data: { title: 'Carrier BID - WARP Admin' } },
  { path: 'bid-sessions/add', component: BidSessionCreateComponent, data: { title: 'Create Bid Session - WARP Admin' } },
  { path: 'bid-sessions/:id', component: BidSessionDetailComponent, data: { title: 'Bid Session - WARP Admin' } },
  { path: 'bid-sessions/:id/add-routes', component: BidSessionAddRouteComponent, data: { title: 'Add Route For Bid Session - WARP Admin' } },
];

@NgModule({
  imports: [RouterModule.forChild(carrierSalesRoutes)],
  exports: [RouterModule]
})
export class CarrierSalesRoutingModule { }
